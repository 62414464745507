import React, { use, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchProductsRequest,
  fetchProductsSuccess,
  fetchProductsFailure,
} from '../redux/actions/productActions';
import { addToCart, incrementItem, decrementItem, removeFromCart, clearCart } from '../redux/actions/cartActions';
import { getDocs, collection, query, where, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import '../style/productDetail.css';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'; // Import axios
// import { useParams } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';

function ShowRestaurantsProducts() {
  const paramsdata = useParams();
  const restaurantId = paramsdata.restaurantId;
  const Distance_km=paramsdata.distance;
  const hotelename=paramsdata.from;

  const [userId, setUserId] = useState(null);
  useEffect(() => {
    window.Telegram.WebApp.ready();
    // Check if Telegram Web App is ready
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready(); // Signal that the app is ready
    }
    dispatch(clearCart());
  }, []);

  
  useEffect(() => {
    // Retrieve the stored profile from localStorage
    const storedProfile = localStorage.getItem('userProfile');

    if (storedProfile) {
      const profileData = JSON.parse(storedProfile);
      setUserId(profileData.userId); // Set userId from stored profile
    }
  }, []);
  useEffect(() => {
    window.Telegram.WebApp.ready();
    // Ensure Telegram WebApp is available and userId is not already set
    if (!userId && window.Telegram && window.Telegram.WebApp) {
      const user = window.Telegram.WebApp.initDataUnsafe?.user;
      if (user) {
        setUserId(user.id); // Set userId from Telegram WebApp
      } else {
        console.log("User ID is not available from Telegram Web App");
      }
    }
  }, [userId]); // Add userId as a dependency to check if it's updated



  const navigate = useNavigate(); // Initialize the navigate function

  // added new 
  useEffect(() => {
    // Show Telegram Web App back button
    window.Telegram.WebApp.BackButton.show();
    
    // Set back button functionality
    window.Telegram.WebApp.BackButton.onClick(() => {
        navigate(-1); // Navigate back
    });

    // Clean up when component is unmounted
    return () => {
        window.Telegram.WebApp.BackButton.hide();
    };
}, [navigate]);
  // end add new

  const location = useLocation(); // Access the location object

  const { restaurantdata } = location.state || {}; // Access state
  
  const [isLoading, setIsLoading] = useState(false);
  
  const dispatch = useDispatch();
  const { loading, products, error } = useSelector((state) => state.products);
  const cartItems = useSelector((state) => state.cart.items) || [];
  const [searchTerm, setSearchTerm] = useState('');
  const [showCart, setShowCart] = useState(false);
  
 
  const [uuid, setUuid] = useState(''); // State to store the UUID
  console.log(uuid);

  // Method to generate a shortened UUID and return it
  const generateUUID = () => {
    const newUUID = uuidv4(); // Generate a new UUID
    const shortenedUUID = newUUID.replace(/-/g, '').substring(0, 7); // Remove hyphens and limit to 7 characters
    setUuid(shortenedUUID); // Update state with the new shortened UUID
    
    return shortenedUUID; // Return the shortened UUID
  };



    useEffect(() => {
      window.Telegram.WebApp.ready();
      // Telegram Back Button setup (assuming you have a `navigate` function)
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        // Hide main button when back button is clicked
        window.Telegram.WebApp.MainButton.hide(); 
        // window.Telegram.WebApp.BackButton.hide(); 
        dispatch(clearCart());
        navigate("/"); // Navigate back
      });

      // Show and configure the main button as "Order Now"
      const mainButton = window.Telegram.WebApp.MainButton;
      if(cartItems.length>0){
        // mainButton.setText("Order Now || አሁን ይዘዙ")
        
        // .show()
        // .onClick(async () => {
        //   try {
        //     // Clear the cart before placing the order
        //     dispatch(clearCart());
        //     await handlePlaceOrder();
        //     mainButton.showProgress(true); // Show progress indicator

           
        //     mainButton.showProgress(false); // Hide progress indicator
        //     mainButton.hide(); // Hide main button after successful order

        //   } catch (error) {
        //     console.error('Error during order placement:', error);
        //     alert('An error occurred. Please try again later.');
        //     mainButton.showProgress(false); // Hide progress indicator
        //   }
        // });

      }else{
        mainButton.hide();
      }
      // Update isEmptyCart state based on cart items
      // setIsEmptyCart(cartItems.length === 0);

      // Cleanup function 
      return () => {
        window.Telegram.WebApp.BackButton.hide(); 
        window.Telegram.WebApp.MainButton.hide(); 
      };
    }, [cartItems]); // Re-run useEffect when cartItems change

  
  useEffect(() => {
    const fetchProducts = async () => {
      dispatch(fetchProductsRequest());
      try {
        const q = query(collection(db, 'Products'), where('h_id', '==', restaurantId));
        const querySnapshot = await getDocs(q);
        const fetchedProducts = [];

        querySnapshot.forEach((doc) => {
          fetchedProducts.push({ id: doc.id, ...doc.data() });
        });

        dispatch(fetchProductsSuccess(fetchedProducts));
      } catch (error) {
        dispatch(fetchProductsFailure(error.message));
      }
    };
    fetchProducts();
  }, [dispatch, restaurantId]);

  if (loading) {
    return <div className='loading-container-detail'>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const filteredProducts = products.filter(product =>
    product.p_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fallbackImage = 'https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=600,height=400,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/bb23dac4-fff6-429a-96c5-2100a349430b.jpeg';

  const handleAddToCart = (product) => {
    dispatch(addToCart({ ...product, quantity: 1 }));
  };

  const handleIncrement = (productId) => {
    dispatch(incrementItem(productId));
  };

  const handleDecrement = (productId) => {
    const cartItem = cartItems.find(item => item.id === productId);
    if (cartItem) {
      if (cartItem.quantity === 1) {
        dispatch(removeFromCart(productId)); // Remove from cart if quantity is 1
        
      } else {
        dispatch(decrementItem(productId)); // Just decrement otherwise
      }
    }
  };
  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCart(productId));
  };

  const closeCart = () => {
    setShowCart(false);

  };

  const sendOrderToTelegram = async (order,documentId) => {
    // const sendMessageto=[order.telegramId,order.host_name,order.user_id]
    const orderItemsList = order.orders.map((item, index) => {
      return `${index + 1}  - ${item.productName.padEnd(10)}     ${item.price.toFixed(2).padEnd(6)}    ${item.quantity}`;
    }).join('\n');

    // Retrieve user data from local storage
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
    const useraddress=userProfile.university || 'N/A';
    const name = userProfile.name || 'N/A'; // Fallback to 'N/A' if name is not available
    const phone = userProfile.phone || 'N/A'; // Fallback to 'N/A' if phone is not available
    const blockNumber = userProfile.dormNumber || 'N/A'; // Fallback to 'N/A' if dormNumber is not available
    //  track link
    const track_data=order.tracklink;
    console.log('Track Link:', track_data);
    
    // Ensure fees and total amount are numbers
    const serviceFee = Number(order.service_fee) || 0;
    const deliveryFee = Number(calculateDeliveryFee(order.totalAmount,order.distance,cartItems.length).toFixed(2)) || 0;
    const totalAmount = Number(order.totalAmount+serviceFee+deliveryFee) || 0;

    const message = `
*New order is coming from The Restaurant: ${order.from_hotel}*
*Order Products List:*
ID   Name         Price       Quantity
----------------------------------------
${orderItemsList}

*Service Fees:* ${serviceFee.toFixed(2)} ETB
*Delivery Fees:* ${deliveryFee.toFixed(0)} ETB
*Total Price:* ${totalAmount.toFixed(0)} ETB

*Customer Details:*
- Name: ${name}
- Phone: ${phone}
- Address :${useraddress}
- Block Number: ${blockNumber}


*Distance (Kilometers):*
-Track Link: [Track here](https://yenetrack.netlify.app/${track_data})
-Location :${order.h_address}
- ${order.distance ? order.distance.toFixed(3) : 'N/A'} KM
    `.trim(); // Remove any leading/trailing whitespace

    sendMessagesSequentially(order, message,userId,documentId);
  };

  const calculateDeliveryFee = (totalPrice, cartItemsLength, distance) => {
    const distanceInMeters = distance * 1000; // Convert kilometers to meters
    const mini_price = 65; // Minimum price per item
    const singleDeliveryFee = 13; // Fee per item for delivery
    const totalprice = totalPrice; // Total price of the cart
    const actualQuantity = cartItemsLength; // Actual number of items in the cart

    // Calculate expected quantity of items based on the total price and the mini_price
    const expectedQuantity = Math.ceil(totalprice / mini_price); // Use Math.ceil to round up

    // Calculate the base delivery fee
    let deliveryFee = (expectedQuantity * singleDeliveryFee) - ((expectedQuantity - actualQuantity) * singleDeliveryFee) / 2;

    // Adjust the fee if the distance is greater than 4000 meters (4 kilometers)
    if (distanceInMeters > 4000) {
      const additionalDistance = distanceInMeters - 4000; // Calculate the distance beyond 4 km
      const additionalKm = Math.ceil(additionalDistance / 1000); // Convert meters to kilometers
      
      // Increase the delivery fee gradually based on the additional kilometers
      // Example: For each additional kilometer, add a smaller fee (e.g., 5 ETB)
      deliveryFee += additionalKm * 2; // Adjust 5 to your desired increment value
  }

    return deliveryFee;
};

// Sequentially send messages to different users
  async function sendMessagesSequentially(order, message, userId,documentId) {
      // Tokens for different bots
      const tokenYeencafe = '5685380140:AAHsgq8SUaQ10yHhxU_b-CYBMG01farQFXw'; // Yeencafe
      const tokenRestaurants = '6054843791:AAHeMZsDT2wYTfMUdPr_rlsYa68fqgdFcsQ'; // Yene Restaurants

      // Define custom messages
      const messageForCustomer = `${message}\nYour order will be delivered in 30 minutes.`;
      const messageForRestaurantAdmin = `For University Admin\n *New order incoming* \n${message}`;
      const messageForCEO = `FOR CEO\n*New order Incoming*\n${message}`;
      const messageForHotelsRestaurants = `Message for restaurants\n *New order incoming*\n${message}`;

      // Define inline keyboards
      const inlineKeyboardCustomer = { inline_keyboard: [] };
      const inlineKeyboardRestaurantAdmin = {
        inline_keyboard: [
         
          [{ text: "Restaurant admin", callback_data: `accept_order_${documentId}` }, { text: "Decline Restaurant", callback_data: `decline_order_${documentId}` }]
        ]
      };
      const inlineKeyboardCEO = {
        inline_keyboard: [
          [{ text: "Accept CEO", callback_data: `accept_order_${documentId}` }, { text: "Decline CEO", callback_data: `decline_order_${documentId}` }]
        ]
      };
      const inlineKeyboardHotelsRestaurants = {
        inline_keyboard: [
          [{ text: "Accept for Hotel", callback_data: `accept_order_${documentId}` }, { text: "Decline for Hotel", callback_data: `decline_order_${documentId}` }]
        ]
      };

      // Sequentially send messages
      try {
        // Send message to customer
        await sendMessageToChat(userId, messageForCustomer, inlineKeyboardCustomer, tokenYeencafe);

        // Send message to restaurant admin
        await sendMessageToChat(order.host_name, messageForRestaurantAdmin, inlineKeyboardRestaurantAdmin, tokenRestaurants);

        // Send message to CEO
        await sendMessageToChat(order.admin, messageForCEO, inlineKeyboardCEO, tokenRestaurants);

        // Send message to hotels/restaurants
        await sendMessageToChat(order.h_telegram, messageForHotelsRestaurants, inlineKeyboardHotelsRestaurants, tokenRestaurants);

      } catch (error) {
        
      }
    }
    // Function to send a message to a specific chat ID
  async function sendMessageToChat(chatId, customMessage, replyMarkup, token) {
      try {
        await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
          chat_id: chatId,
          text: customMessage,
          parse_mode: 'Markdown',
          reply_markup: replyMarkup
        });
        
      } catch (error) {
        
      }
    }
  // Function to handle placing an order
  const handlePlaceOrder = async () => {
    setIsLoading(true); // Show spinner when order starts processing
    const uuid = generateUUID(); // You can now capture the returned UUID
    // Retrieve user data from local storage
    const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
    const userlat=userProfile.lat || 'N/A';
    const userlon=userProfile.lon;
   
    const order = {
      // Assuming you have a userId
      'h_id': restaurantId,
      'telegramId': Number(userId), //  this is used to send the hotel

      'h_telegram': restaurantdata.telegramId,//  this is used to send the hotel
      'host_name': Number(restaurantdata.host_name), // this for university admin
      'admin':761513957, // this is for CEO
      'user_id': Number(userId), // this is for customer id /user id
      "h_address":restaurantdata.h_address,
      'from_hotel': restaurantdata.h_name,
      'with_delivery': "",
      "message":"",
      'order_status': "Pending",
      'order_code': uuid,
      'service_fee': "10",
      'delivery_fee': calculateDeliveryFee(cartItems.reduce((sum, item) => sum + item.p_price * item.quantity, 0),cartItems.length,restaurantdata.distance),
      'distance': restaurantdata.distance,
      'orders': cartItems.map(item => ({
        productId: item.id,
        productName: item.p_name,
        quantity: item.quantity,
        price: item.p_price,
      })),
      totalAmount: cartItems.reduce((sum, item) => sum + item.p_price * item.quantity, 0),
      'order_time': new Date().toISOString(),
      'tracklink':`${restaurantdata.gpsX}/${restaurantdata.gpsY}/${userlat}/${userlon}`
    };

    try {
      // Send the order data to the 'Orders' collection in Firebase
      // await addDoc(collection(db, 'Telegram-OrderdList'), order);
      // alert('Order placed successfully!');
      // Add order to Firestore and get the document reference
      const docRef = await addDoc(collection(db, 'Telegram-OrderdList'), order);
      // Get the document ID
      const documentId = docRef.id;
      await sendOrderToTelegram(order,documentId); // Call function to send message to Telegram
      dispatch(clearCart());
      setShowCart(false);
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.BackButton.hide(); 
      window.Telegram.WebApp.MainButton.hide(); 
      navigate(`/tabbed`);
    } catch (error) {
      alert('Failed to place order: ' + error.message);
      setIsLoading(false); // Hide spinner after order is processed
    }
  }

  return (
    <div className="app-container-detail">
      <div className="search-cart-container1-detail">
        <input
          type="text"
          className="search-input-detail"
          placeholder="Search product"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* <button className="cart-button-detail" onClick={() => setShowCart(!showCart)}>
          {showCart ? 'Hide Cart' : 'Cart'}
        </button> */}
       {cartItems.length > 0 && (
    <button className="cart-button-nearby-detail" onClick={() => setShowCart(!showCart)}>
        {showCart ? 'Hide Cart' : 'Cart'}
        <span className="cart-badge-detail">{cartItems.length}</span>
    </button>
)}

      </div>

      {showProductsgrid()}

      {showCart && (
        <div className={`cart-sidebar ${showCart ? 'slide-in' : 'slide-out'}`}>
          <button className="close-button" onClick={closeCart}>X</button>
          <h2>Cart Items</h2>
          <sub>{hotelename}</sub>
          {cartItems.length === 0 ? (
            <>
            <p>No items in the cart.</p>
            {/* Automatically close the cart when there are no items */}
            {setShowCart(false)}  {/* Remove this line */}
          </>
          ) : (
            <>
            
              {cartItems.map(item => (
                <div key={item.id} className="cart-item">
                  <span>{item.p_name} x {item.quantity}</span>
                  <span>{(item.p_price * item.quantity).toFixed(2)}</span>
                  <button className="remove-button" onClick={() => handleRemoveFromCart(item.id)}>Remove</button>
                  
                </div>
              ))}
              <div class="fee-details">
                 
                  <p>Delivery fee: {calculateDeliveryFee(cartItems.reduce((sum, item) => sum + item.p_price * item.quantity, 0), cartItems.length, Number(Distance_km))} ETB</p>
                  <p>Service Fee: 10 ETB</p>
                  <p class="total-price">
                      Total Price: <span>{(cartItems.reduce((sum, item) => sum + item.p_price * item.quantity, 0) + calculateDeliveryFee(cartItems.reduce((sum, item) => sum + item.p_price * item.quantity, 0), cartItems.length, Number(Distance_km)) + 10).toFixed(2)} ETB</span>
                  </p>
                  <p>Distance in Km: {Number(Distance_km).toFixed(2)} km</p>
              </div>
            {!isLoading ? (

              <><button className="order-button-detail" onClick={handlePlaceOrder}>
                    Order now| አሁን ይዘዙ
                  </button><p>  </p></>

            ) : (
              <div className="spinner"></div> // Show spinner when loading
            )}

            </>
          )}
        </div>
      )}
    </div>
  );
  function showProductsgrid() {
    return (
      <div className="product-list">
        {filteredProducts.map((product) => {
          const cartItem = cartItems.find(item => item.id === product.id);
          const quantity = cartItem ? cartItem.quantity : 0;

          return (
            <div className="product-card-detail" key={product.id}>
              <img
                src={product.p_image}
                alt={product.p_name}
                className="product-image-detail"
                onError={(e) => { e.target.onerror = null; e.target.src = fallbackImage; }}
              />
              <h2 className="product-name-detail">{product.p_name}</h2>
              <p className="product-price-detail">Price: {product.p_price} ETB</p>

              <div className="quantity-controls">
                {quantity > 0 ? (
                  <>
                    <button className="control-button" onClick={() => handleDecrement(product.id)}>-</button>
                    <span className="quantity">{quantity}</span>
                    <button className="control-button" onClick={() => handleIncrement(product.id)}>+</button>
                  </>
                ) : (
                  <button className="add-to-cart-detail-button" onClick={() => handleAddToCart(product)}>Add to Cart</button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default ShowRestaurantsProducts;
