import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Import your Firebase config
import "../style/ProductHome.css"; // Add your styling here

const ProductHomePage = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterNearby, setFilterNearby] = useState(false);
  const [userLocation, setUserLocation] = useState({ lat: null, lon: null });

  // Fetch products from Firestore
  const fetchProducts = async () => {
    const productCollection = collection(db, "E-products");
    const productSnapshot = await getDocs(productCollection);
    const productList = productSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setProducts(productList);
  };

  // Get the user's location from localStorage (you can also use Geolocation API)
  const setUserLocationFromStorage = () => {
    const retrievedProfile = localStorage.getItem("userProfile");
    if (retrievedProfile) {
      const userProfile = JSON.parse(retrievedProfile);
      setUserLocation({
        lat: userProfile.lat,
        lon: userProfile.lon,
      });
    }
  };

  useEffect(() => {
    fetchProducts();
    setUserLocationFromStorage(); // Set user's location on mount
  }, []);

  // Filter products by search query
  const filteredProducts = products.filter((product) => {
    return (
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.price.toString().includes(searchQuery)
    );
  });

  // Filter products by proximity to user's location
  const filterByProximity = (product) => {
    if (filterNearby && userLocation.lat && userLocation.lon) {
      const distance = calculateDistance(
        userLocation.lat,
        userLocation.lon,
        product.latitude,
        product.longitude
      );
      return distance <= 50; // Show products within 50 km
    }
    return true; // Show all products if the filter is off
  };

  // Calculate distance between two geo-coordinates (Haversine formula)
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  return (
    <div className="product-home-page">
      {/* Search and Filter Bar */}
      <div className="search-filter-bar">
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          className="filter-button"
          onClick={() => setFilterNearby(!filterNearby)}
        >
          {filterNearby ? "Show All" : "Show Nearby"}
        </button>
      </div>

      {/* Product Grid */}
      <div className="product-grid">
        {filteredProducts
          .filter(filterByProximity)
          .map((product) => (
            <div key={product.id} className="product-card">
              <img
                src={product.imageUrl || "placeholder.jpg"}
                alt={product.name}
              />
              <h5>{product.name}</h5>
              <p>Price: ${product.price} ETB</p>
              {filterNearby && userLocation.lat && userLocation.lon && (
                <p>
                  Distance:{" "}
                  {calculateDistance(
                    userLocation.lat,
                    userLocation.lon,
                    product.latitude,
                    product.longitude
                  ).toFixed(2)}{" "}
                  km
                </p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductHomePage;
